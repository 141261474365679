import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query FineDiningImages {
      allFile(filter: { relativeDirectory: { eq: "visuals-fine-dining" } }) {
        edges {
          node {
            base
            id
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: WEBP
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {data.allFile.edges.map(
        ({ node }, idx) =>
          node.base === props.imageName && (
            <GatsbyImage
              image={getImage(node)}
              key={idx.toString()}
              className={props.className}
              style={props.style}
              alt={props.alt}
            />
          )
      )}
    </>
  );
};

Image.propTypes = {
  imageName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  alt: PropTypes.string,
};
Image.defaultProps = {
  imageName: "",
  className: "",
  style: {},
  alt: "image",
};

export default Image;
